const tableData = [
  {
    id: 1,
    course: 'Recruiting Manstatusr',
    total_messages: 'recuperar-senha',
    status: 'I',
  }, {
    id: 2,
    course: 'Vocês estão avisados',
    total_messages: 'pagamento-confirmado',
    status: 'A',
  }, {
    id: 3,
    course: 'Recruiting Manstatusr',
    total_messages: 'matricula-em-grupo',
    status: 'I',
  }, 
]

export { tableData };
