<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { tableData } from "./data-advancedtable";

/**
 * Communitites component
 */
export default {
  page: {
    title: "Comunidades",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tableData: tableData,
      title: "Comunidades",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Comunidades",
          active: true,
        },
      ],
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        course: "Curso",
        totalMessages: "Total de Mensagens",
        status: "Status",
        actions: "Ações",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        {
          key: "id",
          sortable: true,
          label: this.fieldAliases.id,
          thStyle: { width: "8%" },
        },
        { key: "course", sortable: true, label: this.fieldAliases.course },
        { key: "total_messages", sortable: true, label: this.fieldAliases.totalMessages, thStyle: { width: "20%" } },
        { key: "status", sortable: true, label: this.fieldAliases.status, thStyle: { width: "10%" } },
        {
          key: "actions",
          sortable: true,
          label: this.fieldAliases.actions,
          thStyle: { width: "110px" },
        },
      ];
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
      :addText="'Adicionar Novo E-mail'"
      :enableCad="false"
      @newCad="handleNewCad"
    />
    <div class="row mb-2">
      <div class="col-12 col-md-3">
        <div class="card card-statistic-2 h-100 shadow-sm">
          <div class="card-body p-3">
            <div class="card-stats h-100">
              <div
                class="card-stats-total d-flex align-items-center justify-content-center justify-content-lg-start h-100"
              >
                <div class="card-icon bg-danger text-center rounded p-3" style="margin-right: 10px">
                  <i class="fas fa-ban h2 m-0 text-light"></i>
                </div>
                <div class="card-total ml-3 ml-lg-2">
                  <p class="m-0">Denúncias</p>
                  <p class="total-count h2 m-0 font-weight-bold">3</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Comunidades</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-4">
              <div class="col-12 col-md-3">
                  <button 
                    disabled="" 
                    type="button" 
                    class="btn btn-block btn-success btn-status-community w-100 p-3 shadow-sm p-3 mb-5 rounded" 
                    data-status="active"
                  >Ativar comunidades</button>
              </div>
              <div class="col-12 col-md-3">
                  <button  
                    disabled="" 
                    type="button" 
                    class="btn btn-block btn-danger btn-status-community w-100 p-3 shadow-sm p-3 mb-5 rounded" 
                    data-status="inactive"
                  >Inativar comunidades</button>
              </div>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select
                      class="form-control form-control-sm form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                    Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">
                    <span
                      class="badge ms-1"
                      :class="
                        obj.item.status == 'A' ? 'bg-success' : 'bg-warning'
                      "
                    >
                      <h6 class="m-0">
                        {{ obj.item.status == "A" ? "Ativo" : "Inativo" }}
                      </h6>
                    </span>
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="category">
                  <div class="actions-cell">
                    <b-button
                      class="w-100"
                      pill
                      @click="deleteItem(category.item)"
                      variant="outline-primary"
                    >
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
